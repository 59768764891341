import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout/Layout";
import Post from "../components/Post/Post";
import { useSiteMetadata } from "../hooks";
import type { MarkdownRemark } from "../types";

type Props = {
  data: {
    markdownRemark: MarkdownRemark
  }
};

const PostTemplate = ({ data }: Props) => {
  const {
    title: siteTitle,
    subtitle: siteSubtitle
  } = useSiteMetadata();
  const { frontmatter } = data.markdownRemark;
  const {
    title: postTitle,
    description: postDescription,
    socialImage
  } = frontmatter;
  const metaDescription = postDescription !== null ? postDescription : siteSubtitle;
  const readingTime = Math.round(data.markdownRemark.fields.readingTime.minutes);

  return (
    <Layout title={`${postTitle} - ${siteTitle}`} description={metaDescription}
            socialImage={socialImage}>
      <Post post={data.markdownRemark} readingTime={readingTime} />
    </Layout>
  );
};

export const query = graphql`
  query PostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      fields {
        slug
        tagSlugs
        readingTime {
          minutes
        }
      }
      frontmatter {
        date
        description
        tags
        title
        socialImage
      }
    }
  }
`;

export default PostTemplate;

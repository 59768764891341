import * as React from "react";
import Contacts from "../../Sidebar/Contacts/Contacts";
import * as styles from "./Author.module.scss";
import { useSiteMetadata } from "../../../hooks";

const BottomPostAuthor = () => {
  const { author } = useSiteMetadata();
  const contacts = author.contacts;

  return (
    <div className={styles["author"]}>
      <img className={styles["author__photo"]} src={author.photo} />
      <p className={styles["author__bio"]}>
        {author.bio}
      </p>
      <Contacts contacts={contacts} />
    </div>
  );
};

export default BottomPostAuthor;

import * as React from "react";
import * as styles from "./Content.module.scss";

import Meta from "../Meta/Meta";

type Props = {
  body: string,
  title: string,
  date: string,
  readingTime: number
};

const Content = ({
                   body,
                   title,
                   date,
                   readingTime
                 }: Props) => (
  <div className={styles["content"]}>
    <h1 className={styles["content__title"]}>{title}</h1>
    <div className={styles["content__date"]}>
      <Meta date={date} readingTime={readingTime} />
    </div>
    <div className={styles["content__body"]} dangerouslySetInnerHTML={{ __html: body }} />
  </div>
);

export default Content;

import * as React from "react";
import { FaCalendarAlt, FaClock } from "react-icons/fa";
import * as styles from "./Meta.module.scss";

type Props = {
  date: string,
  readingTime: number,
};

function formatDate(date) {
  return new Date(date).toLocaleDateString("tr-tr", {
    year: "numeric",
    month: "long",
    day: "numeric"
  });
}

const Meta = ({
                date,
                readingTime
              }: Props) => (
  <div className={styles["meta"]}>
    <div className={styles["meta__date"]}>
      <FaCalendarAlt /> {formatDate(date)}
    </div>
    <FaClock /> {readingTime} Dakika
    <div className={styles["meta__divider"]}>
    </div>
  </div>
);

export default Meta;
